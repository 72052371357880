.footericons {
  color: black;
}

.site-footer {
  background-image: linear-gradient(to right, #004cff, #ffffff, #ff0000);
  min-height: 8rem;
}

.full-width {
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  max-width: 100vw;
  position: relative;
  right: 50%;
  width: 100vw;
}
