.accordionIMG {
  max-width: 100%;
}

@media (min-width: 992px){
  .accordionIMG {
    max-width: 70%;
  }
}

@media (min-width: 1300px){
  .accordionIMG {
    max-width: 50%;
  }
}

.bgfaqdark {
  /* outline: 3px solid black; */
  font-size: 1.1em;
  background-size: cover;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
}
