.bannerDonateOrg {
  outline: 3px solid black;
  background-color: rgba(169, 103, 9, 0.7);
  color: rgb(255, 255, 255);
  text-shadow: 0 0 3px #000000, 0 0 5px #000000;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.bannerDonateGrn {
  outline: 3px solid black;
  background-color: rgba(32, 96, 38, 0.7);
  color: rgb(255, 255, 255);
  text-shadow: 0 0 3px #000000, 0 0 5px #000000;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.bannerDonateRed {
  outline: 3px solid black;
  background-color: rgb(129, 0, 0);
  color: rgb(255, 255, 255);
  opacity: 0.75;
  text-shadow: 0 0 3px #000000, 0 0 5px #000000;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}



