.lrmargin10 {
  margin-left: 10%;
  margin-right: 10%;  
}

.bggrn {
  background-color: rgba(32, 96, 38, 0.5);
  text-shadow: 0 0 3px #000000, 0 0 5px #000000;
  color: rgb(255,255,255);
}

.bgLornge {
  background-color: rgba(189, 113, 7, 0.7);
  color: rgb(255, 255, 255);
  text-shadow: 0 0 3px #000000, 0 0 5px #000000;
  font-size: 1.2em;
}

.lrmargin3 {
  margin-left: 3%;
  margin-right: 3%;  
}

.definition {
  max-height: 300px;
}

.hand:hover {
  cursor: pointer;
}

