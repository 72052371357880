body {
  /* pick fonts */
  font-family:'Kanit', 'Roboto', 'sans-serif';
}

.navfont {
  font-size: 2em; 
}

.navicons {
  font-size: 48;
}


/* .navbar-brand img {
  width: 200%;
  height: 200%;
  margin-right: 2rem;
} */

