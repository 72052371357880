.firstImage {
  background-image: url("../assets/flag-bricks.jpg");
  margin-left: 5%;
  max-width: 90%;
  min-height: 600px;
  background-size: cover;
  text-shadow: 0 0 2px rgb(0, 0, 0), 0 0 5px rgb(0, 0, 0);
}

.imageText {
  text-shadow: 0 0 3px #ffffff, 0 0 5px #ffffff;
  /* outline: 3px solid black; */
  min-height: 100%;
  display: inline;
}

.imageTextProps1 {
  color: blue;
  text-align: left;
}

.imageTextProps2 {
  color: red;
  float: right;
}

.rwb-bg {
  background: linear-gradient(to right bottom, #004cff, #ffffff, #ff0000);
}

.full-width {
	left: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
	max-width: 100vw;
	position: relative;
	right: 50%;
	width: 100vw;
}

.banner1 {
  outline: 3px solid black;
  background-color: rgb(211, 158, 34);
  color: #000;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  opacity: 1;
}

.banner2 {
  outline: 3px solid black;

  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 30%;
  margin-right: 30%;
}

.lrmargin {
  margin-left: 5%;
  margin-right: 5%;  
}

/* .solutions {
  outline: 3px solid black; 
} */

.bgdark {
  /* outline: 3px solid black; */
  font-size: 1.1em;
  background-size: cover;
  background-color: rgba(27, 27, 27, 0.7);
  text-shadow: 0 0 2px rgb(0, 0, 0), 0 0 5px rgb(0, 0, 0);
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.blshadow {
  text-shadow: 0 0 2px rgb(0, 0, 0), 0 0 5px rgb(0, 0, 0);
}

.test {
  color: rgb(255, 40, 180)
}
