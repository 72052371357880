.lrmargin {
  margin-left: 5%;
  margin-right: 5%;
}

.cards {
  z-index: 0;
  position: relative;
}

.cards:hover {
  z-index: 1;
}

.textBlack {
  color: #000;
}
